window.addEventListener('DOMContentLoaded', initMenu('header__burger', 'header__menu', 'header__menu-anchor'))

function initMenu(burgerClass, menuClass, anchorClass) {
    const burger = document.querySelector(`.${burgerClass}`)
    const menu = document.querySelector(`.${menuClass}`)
    const anchors = menu.querySelectorAll(`.${anchorClass}`)
    const body = document.body;

    if (burger && menu) {
        burger.addEventListener('click', () => {
            if (burger.classList.contains(`${burgerClass}_active`)) {
                remove()
            } else {
                add()
            }
        })
        window.addEventListener('resize', resizeControl)
    } else {
        throw new Error(e)
    }

    if(anchors) {
        anchors.forEach(el => {
            el.addEventListener('click', remove)
        })
    }

    function resizeControl() {
        const w = document.documentElement.clientWidth;
        if (w > 899) {
            remove()
        }
    }

    function remove() {
        body.classList.remove('lock')
        burger.classList.remove(`${burgerClass}_active`)
        menu.classList.remove(`${menuClass}_active`)
    }

    function add() {
        body.classList.add('lock')
        burger.classList.add(`${burgerClass}_active`)
        menu.classList.add(`${menuClass}_active`)
    }
}
