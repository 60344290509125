require('../scss/app.scss');

require('./default/menu');
require('./default/calendar');
require('./default/cookie_banner');

window.noZensmooth = true;
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');

// Импортировать код fancybox только после загрузки страницы для элемента с классом '.gallery'
window.addEventListener('load', () => {
    let gallery = document.querySelector('.gallery') ? true : false

    if(gallery) {
        require('@fancyapps/fancybox')
        require('@fancyapps/fancybox/dist/jquery.fancybox.css');
    }
})

