import Calendar from 'color-calendar';

window.addEventListener('load', () => {
    let check = document.getElementById('calendar') ? true : false
    // return today date as date format
    const fullDateToday = new Date()
    //return next day as date format
    const fullNextDay = new Date(new Date().setDate(fullDateToday.getDate() + 1))
    // return string "2021"
    const currentYear = getYear(fullDateToday)
    // return string "8" (1 to 12)
    const currentMonth = getMonth(fullDateToday)
    // return string "29"
    const currentDay = getDay(fullDateToday)
    // return string "30"
    const nextDay = getDay(fullNextDay)


    function getYear(date) {
        return date.toDateString().split(' ')[3]
    }
    function getMonth(date) {
        return date.getMonth() + 1
    }
    function getDay(date) {
        return date.toDateString().split(' ')[2]
    }

    const language = document.documentElement.lang
    let emptyText, customWeekday, customMonth;

    if (language == 'en') {
        emptyText = 'Event\'s not found'
        customWeekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        customMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    } else {
        emptyText = 'Мероприятий не запланировано'
        customWeekday = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
        customMonth = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    }


    if (check) {
        let eventsCal = new Calendar({
            id: '#calendar',
            startWeekday: 1,
            customWeekdayValues: customWeekday,
            customMonthValues: customMonth,
            dateChanged: (currentDate, events) => displayEventOnSelectDay(currentDate, events, true),
        })

        eventsCal.setEventsData(calendar_data)


        // function displayAllEvent() {
        //     let check = eventsCal.getEventsData().length ? true : false
        //     if (check) {
        //         displayEventOnSelectDay(null, eventsCal.getEventsData(), false)
        //     }
        // }
//TODO переписать в 1 функцию
        function displayEventOnSelectDay(currentDate, events, displayPast) {
            let counter = 0;
            let success = false
            const events_display = document.querySelector('.calendar__list');
            let events_html = '';

            events.forEach(event => {
                if (counter < 4) {
                    let eventImage
                    // дата начала события
                    let eventDate = new Date(event.start)
                    // год начала события
                    let eventYear = getYear(eventDate)
                    // месяц начала события
                    let eventMonth = getMonth(eventDate)
                    // день начала события
                    let eventDay = getDay(eventDate)

                    if(eventYear == currentYear) {

                        if(eventMonth == currentMonth) {

                            if(eventDay == nextDay || eventDay == currentDay) {
                                events_html += `<li class="calendar__item calendar__item_nearest">`
                                eventImage = '/static/images/base/line-red.svg'
                                success = true
                            }

                        }
                    }
                    if(!success) {
                        if (eventDate > fullDateToday) {
                            events_html += `<li class="calendar__item calendar__item_future">`
                            eventImage = '/static/images/base/line-blue.svg'
                        }

                        if (eventDate < fullDateToday) {
                            if(!displayPast) {
                                return
                            } else {
                                events_html += `<li class="calendar__item calendar__item_past">`
                                eventImage = '/static/images/base/line-gray.svg'
                            }
                        }
                    }

                    events_html +=
                        `<div class="calendar__list-date">Начало: ${eventDate.getDate().toString().padStart(2, '0')}.${(eventDate.getMonth() + 1).toString().padStart(2, '0')}.${eventDate.getFullYear()} в ${eventDate.getHours().toString().padStart(2, '0')}:${eventDate.getMinutes().toString().padStart(2, '0')}</div>
                        <a class="calendar__list-event" href="${event.url}">
                            <span class="calendar__link" >
                                ${event.name}
                                <img src="${eventImage}" alt="${event.name}">
                            </span>
                            <span class="calendar__list-text">Курс: ${event.course}</span>
                            <span class="calendar__list-text">${event.text}</span>
                            <i class="calendar__list-module">${event.module}</i>
                        </a>
                    </li>`
                    success = false
                    counter++
                }
            });
            if (events_html) {
                events_display.innerHTML = events_html;
            } else {
                events_display.innerHTML = `
                <li class="calendar__item">
                    ${emptyText}
                </li>`;
            }
            color()
        }

        function color() {
            let selectedYear, selectedMonth

            if(eventsCal) {
                // текущие год и месяц, которые выбраны
                let selectedDate = new Date(eventsCal.getSelectedDate())
                selectedYear = getYear(selectedDate)
                selectedMonth = getMonth(selectedDate)
            }


            calendar_data.forEach(data => {

                // дата начала события
                let eventDate = new Date(data.start)
                // день начала события
                let eventDay = getDay(eventDate)

                if(selectedYear == currentYear) {

                    if(selectedMonth == currentMonth) {

                            let dayWithEvent = document.querySelectorAll('.calendar__day.calendar__day-active.calendar__day-event')

                            if(dayWithEvent.length > 0) {
                                dayWithEvent.forEach(el => {
                                    let dayText = el.querySelector('.calendar__day-text')
                                    if(parseInt(dayText.textContent) == currentDay) {
                                        // ближайшие события текущего месяца
                                        dayText.nextElementSibling.style.background = '#D7182A'
                                    } else if (parseInt(dayText.textContent) >= nextDay) {
                                        // будущие события текущего месяца
                                        dayText.nextElementSibling.style.background = '#0066B3'
                                    } else if (parseInt(dayText.textContent) < nextDay) {
                                        // прошедшие события текущего месяца
                                        dayText.nextElementSibling.style.background = '#9F9F9F'
                                    }
                                })
                            }

                    } else if (selectedMonth > currentMonth) {
                        // будущие события следующих месяцев
                        let dayWithEvent = document.querySelectorAll('.calendar__day.calendar__day-active.calendar__day-event')
                        dayWithEvent.forEach(el => {
                            let dayText = el.querySelector('.calendar__day-text')
                                dayText.nextElementSibling.style.background = '#0066B3'
                        })
                    } else if (selectedMonth < currentMonth) {
                        // прошедшие события прошлых месяцев
                        let dayWithEvent = document.querySelectorAll('.calendar__day.calendar__day-active.calendar__day-event')
                        dayWithEvent.forEach(el => {
                            let dayText = el.querySelector('.calendar__day-text')
                                dayText.nextElementSibling.style.background = '#9F9F9F'
                        })
                    }
                }
            })
        }

        // displayAllEvent()
    }
})
